import { Bold } from "tiptap-extensions";

export class Autofill extends Bold {
  get name() {
    return "autofill";
  }

  get schema() {
    return {
      attrs: {
        class: {
          default: "autofill",
        },
      },
      parseDOM: [
        {
          tag: "span.autofill",
        },
      ],
      toDOM: () => ["span", { class: "autofill" }, 0],
    };
  }
}
