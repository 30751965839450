import { Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ComponentBlockSubtitles from "./index.vue";

export const BlockSubtitles = Node.create({
  name: "customVueNode",
  group: "block",

  parseHTML() {
    return [
      {
        tag: "block-subtitles",
        getAttrs: (dom) => ({
          dataBlockId: dom.getAttribute("data-block-id"),
          dataDocumentId: dom.getAttribute("data-document-id"),
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["block-subtitles", HTMLAttributes, 0];
  },

  addAttributes() {
    return {
      dataBlockId: {
        default: null,
      },
      dataDocumentId: {
        default: null,
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ComponentBlockSubtitles);
  },
});
