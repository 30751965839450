<template>
  <div v-click-outside="handleClickOutside">
    <b-button
      v-if="isShowingIcon"
      @click="isShowingMenu = !isShowingMenu"
      variant="transparent"
      class="p-0 position-absolute"
      :style="positionIcon"
    >
      <img src="@/assets/icons/plus-button.svg" width="25" height="25" />
    </b-button>

    <div
      v-if="isShowingMenu"
      :style="styleMenu"
      class="position-absolute pl-4 pr-4 bg-dark-jungle rounded-lg d-flex align-items-middle"
      style="z-index: 1000; width: 200px"
    >
      <icon @click="handleHeading" icon="text-formatting" size="md" class="mr-2" />
      <span class="ml-2 mr-2 border-right" />

      <icon @click="handleBulletList" icon="text-list-bullet" size="sm" class="mr-2" />
      <icon @click="handleOrderedList" icon="text-list-numbered" size="sm" class="mr-2" />
      <icon @click="handleBlockquote" icon="text-quote" size="sm" class="mr-2" />
      <span class="ml-2 mr-2 border-right" />
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

import { DEFAULT_BUBBLE_SIZE } from "@/constants/tiptap";

import Icon from "@/components/Base/Icon.vue";

export default {
  name: "Floating",
  components: { BButton, Icon },
  props: {
    editor: {
      required: true,
    },
  },
  computed: {
    styleMenu() {
      const { positionMenu } = this;
      const { width, height } = DEFAULT_BUBBLE_SIZE;

      return `${positionMenu}; width: ${width}px; height: ${height}px`;
    },
  },
  data: () => ({
    positionIcon: "",
    positionMenu: "",

    isShowingIcon: false,
    isShowingMenu: false,
  }),
  mounted() {
    this.editor.on("transaction", () => {
      this.updateIconPosition();
    });
  },
  beforeDestroy() {
    this.editor.off("transaction");
    this.editor.destroy();
  },
  methods: {
    handleHeading() {
      this.editor
        .chain()
        .focus()
        .toggleHeading({ level: 1 })
        .run();

      this.isShowingMenu = false;
    },
    handleBulletList() {
      this.editor
        .chain()
        .focus()
        .toggleBulletList()
        .run();
      this.isShowingMenu = false;
    },
    handleOrderedList() {
      this.editor
        .chain()
        .focus()
        .toggleOrderedList()
        .run();
      this.isShowingMenu = false;
    },
    handleBlockquote() {
      this.editor
        .chain()
        .focus()
        .toggleBlockquote()
        .run();
      this.isShowingMenu = false;
    },

    handleClickOutside() {
      const { isShowingMenu } = this;
      if (isShowingMenu) this.isShowingMenu = false;
    },
    updateIconPosition() {
      const { state } = this.editor;
      const { selection } = state;
      const { empty, $anchor } = selection;

      if (empty) {
        if (!$anchor.doc.textContent) {
          const { from } = selection;
          const coords = this.editor.view.coordsAtPos(from);
          const { top } = coords;

          this.positionIcon = `left: 0; top: ${top - 45}px`;
          this.positionMenu = `left: 30px; top: ${top - 55}px`;

          this.isShowingIcon = true;
        } else {
          this.isShowingIcon = false;
        }
      } else {
        this.isShowingIcon = false;
      }
    },
  },
};
</script>

<style></style>
