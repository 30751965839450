<template>
  <div :style="style" class="pl-4 pr-4 bg-dark-jungle rounded-lg d-flex align-items-middle" style="z-index: 1000">
    <icon @click="handleHeading" icon="text-formatting" size="md" class="mr-2" />
    <span class="ml-2 mr-2 border-right" />

    <icon @click="handleBulletList" icon="text-list-bullet" size="sm" class="mr-2" />
    <icon @click="handleOrderedList" icon="text-list-numbered" size="sm" class="mr-2" />
    <icon @click="handleBlockquote" icon="text-quote" size="sm" class="mr-2" />
    <span class="ml-2 mr-2 border-right" />

    <icon @click="handleBold" icon="text-bold" size="sm" class="mr-2" />
    <icon @click="handleItalic" icon="text-italic" size="sm" class="mr-2" />
    <icon @click="handleUnderline" icon="text-underline" size="sm" class="mr-2" />
    <icon @click="handleStrike" icon="text-strike" size="sm" class="mr-2" />
  </div>
</template>

<script>
import { ACTIONS_REWRITE } from "@/constants/ai";
import { DEFAULT_BUBBLE_SIZE } from "@/constants/tiptap";

import Icon from "@/components/Base/Icon.vue";

export default {
  name: "FloatingMenu",
  components: { Icon },
  props: {
    editor: {
      required: true,
    },
  },
  computed: {
    style() {
      const { x, y } = this.position;
      const { width, height } = DEFAULT_BUBBLE_SIZE;

      return { position: "fixed", left: `${x}px`, top: `${y}px`, width: `${width}px`, height: `${height}px` };
    },
  },
  data: () => ({
    position: { x: 0, y: 0 },

    ACTIONS_REWRITE,
  }),
  created() {
    const { view } = this.editor;
    const { selection } = this.editor.state;

    const { from, to } = selection;

    const start = view.coordsAtPos(from);
    const end = view.coordsAtPos(to);

    const midX = (start.left + end.right) / 2;

    this.position.x = midX - DEFAULT_BUBBLE_SIZE.width / 2;
    this.position.y = start.top - DEFAULT_BUBBLE_SIZE.height - 10;
  },
  methods: {
    handleBold() {
      this.editor
        .chain()
        .focus()
        .toggleBold()
        .run();
    },
    handleItalic() {
      this.editor
        .chain()
        .focus()
        .toggleItalic()
        .run();
    },
    handleUnderline() {
      this.editor
        .chain()
        .focus()
        .toggleUnderline()
        .run();
    },
    handleStrike() {
      this.editor
        .chain()
        .focus()
        .toggleStrike()
        .run();
    },
    handleHeading() {
      this.editor
        .chain()
        .focus()
        .toggleHeading({ level: 1 })
        .run();
    },
    handleBulletList() {
      this.editor
        .chain()
        .focus()
        .toggleBulletList()
        .run();
    },
    handleOrderedList() {
      this.editor
        .chain()
        .focus()
        .toggleOrderedList()
        .run();
    },
    handleBlockquote() {
      this.editor
        .chain()
        .focus()
        .toggleBlockquote()
        .run();
    },
  },
};
</script>
