<template>
  <node-view-wrapper>
    <spinner-small v-if="isLoading" />

    <div v-else>
      <div class="text-right pr-4">
        <b-button @click="isShowingModal = true" variant="transparent" class="text-white text-uppercase border">
          Export
        </b-button>
      </div>

      <item-subtitle
        v-for="(s, index) in subtitles"
        :key="index"
        :project-id="projectId"
        :document-id="documentId"
        :index="index"
        :started-at="s.startedAt"
        :content.sync="s.content"
      />
    </div>

    <b-modal
      v-model="isShowingModal"
      body-bg-variant="dark"
      @hidden="isShowingModal = false"
      centered
      hide-header
      hide-footer
      size="lg"
    >
      <form @submit.prevent="handleExport">
        <fieldset>
          <h5 class="text-uppercase">Export Transcription</h5>

          <label>
            <input type="checkbox" v-model="isIncludingTimestamps" />
            Include timestamps
          </label>
          <br />

          <label>
            <input type="checkbox" v-model="isSeparatingIntoParagraphs" />
            Separate into paragraphs
          </label>
        </fieldset>

        <div class="mt-4 text-right">
          <b-button @click="handleExport" variant="success" class="text-white text-uppercase">
            Export Now
          </b-button>
        </div>
      </form>
    </b-modal>
  </node-view-wrapper>
</template>

<script>
import { mapActions } from "vuex";
import { GET, UPDATE } from "@/store/actions";

import { NodeViewWrapper } from "@tiptap/vue-2";
import { BModal, BButton } from "bootstrap-vue";
import debounce from "lodash/debounce";

import { stripHtmlTags } from "@/helpers/strings";

import SpinnerSmall from "@/components/Spinner/Small";

import ItemSubtitle from "./Subtitle";

export default {
  components: {
    NodeViewWrapper,
    BModal,
    BButton,
    SpinnerSmall,
    ItemSubtitle,
  },
  props: {
    node: Object,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },

  data: () => ({
    documentId: null,

    subtitles: [{ startedAt: "0:00", content: "" }],

    isIncludingTimestamps: false,
    isSeparatingIntoParagraphs: false,

    isLoading: true,
    isShowingModal: false,
  }),
  mounted() {
    this.triggerGetDocumentBlock();
  },
  watch: {
    subtitles: {
      deep: true,
      handler() {
        this.updateBlock();
      },
    },
  },
  methods: {
    ...mapActions("projectsDocumentsBlocks", { getDocumentBlock: GET, updateDocumentBlock: UPDATE }),
    async triggerGetDocumentBlock() {
      this.isLoading = true;

      const { projectId } = this;
      const { dataDocumentId: documentId, dataBlockId: blockId } = this.node.attrs;
      console.log(this.node.attrs);
      console.log("documentId");
      console.log(documentId);

      this.documentId = documentId;
      const result = await this.getDocumentBlock({ projectId, documentId, blockId });

      this.subtitles = result?.subtitles || [{ startedAt: "0:00", content: "" }];

      this.isLoading = false;
    },
    async triggerUpdateDocumentBlock() {
      this.isSending = true;

      const { projectId, subtitles } = this;
      const { dataDocumentId: documentId, dataBlockId: blockId } = this.node.attrs;

      const fieldPath = "subtitles";
      const value = subtitles;

      await this.updateDocumentBlock({ projectId, documentId, blockId, fieldPath, value });

      this.isSending = false;
    },
    updateBlock: debounce(function(value) {
      this.triggerUpdateDocumentBlock(value);
    }, 2000),

    formatSubtitles() {
      const { subtitles, isSeparatingIntoParagraphs, isIncludingTimestamps } = this;

      return stripHtmlTags(
        subtitles
          .map((sub) => {
            let content = "";

            if (isIncludingTimestamps) {
              content += `${sub.startedAt} `;
            }

            content += sub.content;

            if (isSeparatingIntoParagraphs) {
              content += "\n\n";
            }

            content = content.replaceAll("<br />", "\n");

            return content;
          })
          .join(isSeparatingIntoParagraphs ? "\n" : "")
      );
    },
    handleExport() {
      console.log("export");
      const formattedSubtitles = this.formatSubtitles();

      const blob = new Blob([formattedSubtitles], { type: "text/plain" });
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = "subtitles.txt";
      link.click();

      URL.revokeObjectURL(link.href);
    },
  },
};
</script>
