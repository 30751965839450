<template>
  <b-row no-gutters>
    <b-col cols="12" md="8">
      <tip-tap-headline :value.sync="localName" placeholder="No Name" class="tip-tap-headline mt-4 ml-4 mr-4" />
    </b-col>

    <b-col cols="12" md="4" class="text-right">
      <div class="mt-2 mr-2">
        <b-button @click="$emit('check-task', $event)" :variant="taskVariant" class="mt-1 pl-3 pr-3" :disabled="isSending" pill>
          <img src="@/assets/icons/check.svg" width="20" height="20" class="mr-2" />
          {{ taskText | __t(`projects__task__${toSlug(taskText)}`) }}
        </b-button>

        <base-dropdown class="mt-2 mr-2" @remove="$emit('remove-task')" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";

import TipTapHeadline from "@/components/Forms/TipTap/Headline";
import BaseDropdown from "@/components/Base/Dropdown";

export default {
  name: "HeaderDesktop",
  components: {
    BRow,
    BCol,
    BButton,
    TipTapHeadline,
    BaseDropdown,
  },
  props: {
    taskName: {
      type: String,
      default: "",
    },

    taskVariant: {
      type: String,
      default: "",
    },
    taskText: {
      type: String,
      default: "",
    },

    isSending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localName: {
      get() {
        return this.taskName;
      },
      set(value) {
        this.$emit("update:task-name", value);
      },
    },
  },
  methods: {
    toSlug,
  },
};
</script>
