<template>
  <div v-if="isShowingMentions" class="mentions-popup">
    <ul>
      <li v-for="item in items" :key="item.id" @click="selectItem(item)">
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Mentions",
  props: {
    editor: {
      required: true,
    },

    query: {
      type: String,
      default: "",
    },
    isShowingMentions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("select", item);
    },
  },
};
</script>

<style>
.mentions-popup {
  background-color: red;
}
</style>
