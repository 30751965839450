<template>
  <b-row no-gutters>
    <b-col md="1">
      <div class="mt-2">
        <small class="text-graylight">
          {{ startedAt }}
        </small>
      </div>
    </b-col>

    <b-col>
      <base-tip-tap :value.sync="localContent" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import BaseTipTap from "@/components/Forms/TipTap/Paragraph";

export default {
  name: "ItemSubtitle",
  components: { BRow, BCol, BaseTipTap },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },

    startedAt: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: "",
    },
  },
  computed: {
    localContent: {
      get() {
        return this.content;
      },
      set(value) {
        this.$emit("update:content", value);
      },
    },
  },
};
</script>
