<template>
  <div>
    <div v-for="(ref, index) in references" :key="index" class="message__references ml-3 pt-2 pl-2 pr-2 pb-2 rounded">
      <a :href="getLink(ref)">
        <p class="mb-0 mr-2 float-right">
          <small>
            <u>{{ "View More" | __t("button__view-more") }}</u>
          </small>
        </p>

        <p class="mb-0">
          <span v-if="ref.name" class="message__references-name">{{ ref.name }}</span>
          <span v-else class="message__references-id">{{ ref.id }}</span>
        </p>
      </a>
    </div>
  </div>
</template>

<script>
const { VUE_APP_HOST } = process.env;

export default {
  name: "References",
  props: {
    references: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getLink(ref) {
      const { projectId } = this.$route.params;
      const { id } = ref;

      return `${VUE_APP_HOST}/${projectId}/documents/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.message__references {
  // border: 1px dotted $black;
  background-color: #575d77;

  .message__references-name::before,
  .message__references-id::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/meet-louis.appspot.com/o/common%2Fdocument.svg?alt=media&token=066d7e93-9321-48d2-9c54-03fbf2774c28");
    background-size: cover;
  }
}
</style>
