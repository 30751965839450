<template>
  <div class="base-select-native w-100">
    <vue-select
      :multiple="isMultiple"
      label="text"
      :placeholder="placeholder"
      v-model="localValue"
      :options="options"
      :style="styleCustom"
    />
  </div>
</template>

<script>
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "SelectSearch",
  components: { VueSelect },
  props: {
    label: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "Select option",
    },
    options: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [Object, String, Number],
      default: null,
    },

    isMultiple: {
      type: Boolean,
      default: false,
    },
    styleCustom: {
      type: String,
      default: "",
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(payload) {
        this.$emit("update:value", payload);
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_settings";

.base-select-native {
  .vs__dropdown-toggle {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    color: $black-lighter;
    border: 1px $gray-light-100 solid;
  }
}

.base-select-native {
  .vs__dropdown-toggle {
    background-color: #151718;

    .vs__selected {
      color: white !important;
    }
  }

  .vs__dropdown-option {
    background-color: #151718;
    color: white;
    border-bottom: 1px #272a34 solid;
    transition: transform 0.7s ease-in-out;
  }

  .vs__dropdown-option:hover {
    background-color: #5900d9;
  }

  .vs__dropdown-menu {
    margin-top: 5px;
    border: 0px !important;
    border-radius: 0 0 20px 20px;
    background-color: #151718;
  }
}
</style>
