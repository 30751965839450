<template>
  <div class="select-area rounded-lg p-4">
    <h5 class="text-uppercase">Select a product...</h5>

    <base-search :value.sync="documentId" :options="productsFiltered" />

    <p>Or create a new one</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { stripHtmlTags } from "@/helpers/strings";

import BaseSearch from "@/components/Forms/Base/BaseSelect/Search";

export default {
  name: "SelectDocument",
  components: { BaseSearch },
  computed: {
    ...mapGetters("projectsDocuments", ["itemsSorted"]),
    productsFiltered() {
      const { itemsSorted } = this;

      return itemsSorted.map((item) => {
        return {
          value: item.id,
          text: stripHtmlTags(item.name),
        };
      });
    },
  },
  data: () => ({
    documentId: null,
  }),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.select-area {
  border: 1px solid #5900d9;
  background-color: #151718;
}
</style>
