<template>
  <b-button
    @click="$emit('click')"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    variant="transparent"
    class="p-0"
  >
    <!-- <img v-if="isHovering || isActive" :src="require(`@/assets/icons/${icon}-hover.svg`)" :class="`icon-${size}`" /> -->
    <!-- <img v-else :src="require(`@/assets/icons/${icon}.svg`)" :class="`icon-${size}`" /> -->
    <img :src="require(`@/assets/icons/${icon}.svg`)" :class="`icon-${size}`" />
  </b-button>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  name: "Icon",
  components: { BButton },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isHovering: false,
  }),
};
</script>
