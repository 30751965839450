<template>
  <div class="w-100">
    <div
      ref="droparea"
      @drop.prevent.stop="handleDrop"
      @drag.prevent.stop
      @dragenter.prevent.stop
      @dragstart.prevent.stop
      @dragend.prevent.stop
      @dragover.prevent.stop="isActive = true"
      @dragleave.prevent.stop="isActive = false"
      @click="triggerSelectFile"
      :class="{ is_active: isActive, is_disabled: disabled }"
      class="drop-area w-100 d-flex justify-content-center align-items-center"
    >
      <div class="text-center">
        <img class="mb-3" src="@/assets/icons/cloud-upload.svg" width="50" height="50" />
        <h5 class="text-uppercase">{{ "Drop Your File Like It's Hot..." | __t("upload__drop-your-file-like-its-hot") }}</h5>
        <p>({{ "Or Click Here" | __t("upload__or-click-here") }})</p>

        <input type="file" ref="fileinput" name="file-input" v-show="false" @change="handleFileDialog" />

        <p class="mb-1">
          <small>
            <em>
              {{ "You can upload any" | __t("upload__allowed-file-extensions") }}
              {{ extensions }} {{ "file with a maximum size of" | __t("upload__file-with-a-maximum-size-of") }}
              {{ Number(maxSize / 1000 / 1000 / 1000).toFixed(1) }}GB
            </em>
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ACCEPTED_TYPES } from "@/constants/storage";

import { isAboveLimit } from "@/helpers/users";
// import { isVideoDurationAboveLimit, getUserLimits } from "@/helpers/users";

export default {
  name: "BaseUploadFile",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    previewUrl: {
      type: String,
      default: "",
    },
    maxSize: {
      type: Number,
      default: 4 * 1024 * 1024 * 1024, // 4 GB in bytes
    },
    acceptedTypes: {
      type: Array,
      default: null,
    },
    extensions: {
      type: String,
      default: ".PDF",
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    handleFileDialog(e) {
      if (this.disabled) return;
      this.isActive = false;

      const file = e.target?.files?.[0];

      if (!this.validate(file)) return;
      this.$emit("upload", file);
    },

    handleDrop(e) {
      if (this.disabled) return;
      this.isActive = false;

      const file = e?.dataTransfer?.files?.[0];

      if (!this.validate(file)) return;
      this.$emit("upload", file);
    },

    triggerSelectFile() {
      if (this.disabled) return;
      this.$refs.fileinput.click();
    },

    validate(file) {
      const { acceptedTypes = ACCEPTED_TYPES, maxSize } = this;
      const { type: fileType, size: fileSize } = file;

      if (isAboveLimit("storage", fileSize)) {
        alert(
          this.$options.filters.__t(
            "User doesn't have any space available. Please remove files from the storage.",
            "research__file-storage-limit-error"
          )
        );
        return false;
      }

      if (fileSize > maxSize) {
        alert(
          this.$options.filters.__t(
            `File size exceeds ${fileSize}MB. Please upload a smaller file.`,
            "research__file-size-limit-error"
          )
        );
        return false;
      }

      if (!acceptedTypes.includes(fileType)) {
        alert(
          this.$options.filters.__t(
            `Invalid file type (${fileType} while only ${acceptedTypes} are accepted).`,
            "research__file-type-error"
          )
        );
        return false;
      }

      return true;
    },

    /*  async fileValid(file) {
      if (file.type === "video/mp4") {
        // check video duration
        const value = await new Promise((r) => {
          const fileURL = URL.createObjectURL(file);

          let video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = () => {
            const duration = video.duration;
            if (isVideoDurationAboveLimit(+duration)) r(false);
            else r(true);
          };
          setTimeout(() => r(true), 3000);
          video.src = fileURL;
        });
        return value;
      }
      return true;
    }, */
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_settings";
.drop-area {
  min-height: 200px;
  // border: 1px dashed;
  position: relative;
  cursor: pointer;

  img {
    max-height: 500px;
    max-width: 100%;
  }

  &.is_disabled {
    cursor: not-allowed;
  }
}

input[disabled="disabled"] {
  cursor: not-allowed;
}

/* .theme--default .drop-area {
  border-color: $gray-light-100;
  background-color: #5900d9;

  &.is_active,
  &:hover {
    border-color: $blue-deep;
  }
  &.is_disabled {
    border-color: $gray-light-100 !important;

    p {
      color: $gray-light-100 !important;
    }
  }
} */

.theme--dark .drop-area {
  border-color: $gray-darker;
  background-color: $black-light;
  &.is_active,
  &:hover {
    border-color: white;
    background-color: $black-light-100;
  }
  &.is_disabled {
    border-color: $gray-darker !important;
  }
}
.ql-toolbar.ql-snow {
  border: none;
}
</style>
