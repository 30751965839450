import { Extension } from '@tiptap/core';

function generateUniqueId() {
  return `id-${Math.random().toString(36).substr(2, 9)}`;
}

export const UniqueIdExtension = Extension.create({
  name: 'uniqueId',

  addGlobalAttributes() {
    return [
      {
        types: ['paragraph', 'heading'],
        attributes: {
          id: {
            default: generateUniqueId(),
            parseHTML: element => {
              return {
                id: element.getAttribute('id') || generateUniqueId(),
              }
            },
            renderHTML: attributes => {
              attributes.id = attributes.id || generateUniqueId();
              return {
                'id': attributes.id,
              };
            },
          },
        },
      },
    ];
  },

});