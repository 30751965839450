<template>
  <div class="upload-area rounded-lg p-2" :style="style">
    <base-upload v-if="!file && !isSuccess" @upload="handleUpload" :accepted-types="acceptedTypes" :extensions="extensions" />

    <div v-else-if="file && !isSuccess" class="p-4">
      <fieldset class="mb-3">
        <p class="mb-0">
          <small> {{ file?.name }} - {{ Math.floor(+file?.size / 1000) }}KB </small>
        </p>
      </fieldset>

      <b-progress v-if="progress >= 0" :value="progress" :max="100" show-progress animated class="mt-3">
        <b-progress-bar :value="progress">{{ Number(progress).toFixed(1) }}%</b-progress-bar>
      </b-progress>
    </div>

    <div v-else-if="file && isSuccess" class="p-2 text-center">
      <h5 class="text-uppercase">
        {{ "Tell Me More About Your File..." | __t("projects__task__headline__tell-me-more-about-your-file") }}
      </h5>

      <p class="mt-4 mb-0 text-left">{{ "What is the language?" | __t("projects__task__what-is-the-language") }}</p>
      <b-form-select v-model="fileLanguage" :options="LANGUAGE_CODES_OPTIONS" class="form-control mt-1" />

      <b-button @click="triggerSuccess" class="mt-4" variant="primary">
        {{ "Continue" | __t("button__continue") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE, UPLOAD, TRANSCRIBE_MEDIA } from "@/store/actions";

import { BProgress, BProgressBar, BFormSelect, BButton } from "bootstrap-vue";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_ROLES } from "@/constants/aiChat";
import { LANGUAGE_CODES_OPTIONS } from "@/constants/storage";
import { TASKS } from "@/constants/tasks";

import BaseUpload from "@/components/Forms/Base/BaseUpload";

export default {
  name: "TaskUpload",
  components: { BProgress, BProgressBar, BFormSelect, BButton, BaseUpload },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },
    actionId: {
      type: String,
      default: null,
    },
  },
  computed: {
    extensions() {
      const { acceptedTypes } = this;
      return acceptedTypes.map((item) => `.${item}`).join(", ");
    },
    style() {
      const { isSuccess } = this;
      return isSuccess
        ? "border: 1px solid #5900d9; background-color: #151718;"
        : `border: 1px dashed; background-color: #5900d9;`;
    },
  },
  data: () => ({
    file: null,

    fileId: null,
    fileName: null,
    fileUrl: null,
    fileLanguage: "en-US",

    progress: null,
    acceptedTypes: ["audio/mp3", "audio/mpeg", "video/mp4"],

    isSuccess: false,
    isLoading: false,

    LANGUAGE_CODES_OPTIONS,
  }),
  methods: {
    ...mapActions("projectsTasksDiscussions", { createMessage: CREATE }),
    ...mapActions("projectsTasksTools", { createTranscript: TRANSCRIBE_MEDIA }),
    ...mapActions("storage", { uploadFile: UPLOAD }),
    async triggerSuccess() {
      const { VUE_APP_HOST } = process.env;
      const { projectId, taskId, actionId, fileId, fileName, fileLanguage, file } = this;

      const format = CHATBOT_MESSAGE_TYPE.FILE;
      const role = CHATBOT_ROLES.USER;
      const content = `<a href="${VUE_APP_HOST}/${projectId}/storage/${fileId}" target="_blank"><span class='file-name'>${fileName} (<em>ID: ${fileId}</em>)</span></a>`;

      await this.createMessage({ projectId, taskId, role, format, content, file });

      console.log("file", file);

      if (actionId === TASKS.TRANSCRIBE_MEDIA) {
        await this.createTranscript({ projectId, taskId, actionId, storageId: fileId, fileName, languageCode: fileLanguage });
      }

      this.isSending = false;
    },

    async handleSuccess() {
      const { fileId, fileUrl } = this;

      this.$emit("success", { fileId, fileUrl });
    },
    async handleUpload(file) {
      this.file = file;
      this.fileName = file?.name;

      const { projectId } = this;
      const { isSuccess, id, name, url } = await this.uploadFile({
        projectId,
        file,
        stateCallback: this.stateChanged,
        errorCallback: this.errorCallback,
        uploadedCallback: this.uploadedCallback,
      });

      this.isSuccess = isSuccess;

      if (isSuccess) {
        this.fileId = id;
        this.fileName = name;
        this.fileUrl = url;
      }
    },
    handleProgress(snapshot) {
      this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    },
    handleLoading(value) {
      this.isLoading = value;
    },

    stateChanged(snapshot) {
      this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    },
    errorCallback(message) {
      this.isLoading = false;
      this.handleLoading(this.isLoading);
      this.errorMessage = message;
    },
    uploadedCallback() {
      this.isLoading = false;
      this.handleLoading(this.isLoading);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.theme--default .upload-area {
  border-color: $gray-light-100;

  &.is_active,
  &:hover {
    border-color: $blue-deep;
  }
  &.is_disabled {
    border-color: $gray-light-100 !important;

    p {
      color: $gray-light-100 !important;
    }
  }

  .form-control {
    background-color: #272a34 !important;
    border-color: #9b9b9b;
    color: white;
  }
}
</style>
