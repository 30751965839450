import { Extension } from "tiptap";
import { Plugin } from "prosemirror-state";

export class CleanPaste extends Extension {
  get name() {
    return "clean_paste";
  }

  get plugins() {
    return [
      new Plugin({
        props: {
          transformPastedHTML: (html) => {
            if (html.startsWith("<html")) {
              const doc = new DOMParser().parseFromString(html, "text/html");
              return doc.body.innerHTML;
            }

            return html;
          },
        },
      }),
    ];
  }
}
