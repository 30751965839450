import { Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";

import ComponentBlockContent from "./index.vue";

export const BlockContent = Node.create({
  name: "blockContent",
  group: "block",

  parseHTML() {
    return [
      {
        tag: "block-content",
        getAttrs: (dom) => ({
          dataBlockId: dom.getAttribute("data-block-id"),
          dataDocumentId: dom.getAttribute("data-document-id"),
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["block-content", HTMLAttributes, 0];
  },

  addAttributes() {
    return {
      dataBlockId: {
        default: null,
      },
      dataDocumentId: {
        default: null,
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ComponentBlockContent);
  },
});
