<template>
  <div>
    <header-desktop
      v-if="!isMobile"
      @check-task="triggerCheckTask"
      :task-name.sync="taskName"
      :task-variant="taskVariant"
      :task-text="taskText"
      :is-sending="isSending"
    />
    <header-mobile
      v-else
      @check-task="triggerCheckTask"
      :task-name.sync="taskName"
      :task-variant="taskVariant"
      :task-text="taskText"
      :is-sending="isSending"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { UPDATE, REMOVE, CHECK, UNCHECK } from "@/store/actions";

import { sleep } from "@/helpers/utils";
import { createFireworkSparks } from "@/helpers/animations";

import HeaderDesktop from "./Desktop";
import HeaderMobile from "./Mobile";

export default {
  name: "TaskHeader",
  components: { HeaderDesktop, HeaderMobile },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },
    task: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    taskVariant() {
      const { isDone = false } = this.task || {};

      if (isDone) return "success";
      return "outline-success";
    },
    taskText() {
      const { isDone = false } = this.task || {};

      if (isDone) return "Complete";
      return "Mark Complete";
    },

    taskName: {
      get() {
        return this.task?.name;
      },
      set(name) {
        const { projectId, taskId } = this;
        this.updateTask({ projectId, taskId, name });
      },
    },

    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    isSending: false,
  }),
  methods: {
    ...mapActions("projectsTasks", { updateTask: UPDATE, removeTask: REMOVE, checkTask: CHECK, uncheckTask: UNCHECK }),
    async triggerRemoveTask() {
      this.isSending = true;

      const { projectId, taskId } = this;
      await this.removeTask({ projectId, taskId });

      this.$router.push({ name: "Project" });
      this.isSending = false;
    },

    async triggerCheckTask(event) {
      this.isSending = true;

      const { projectId, taskId } = this;
      const { isDone = false } = this.task;

      if (!isDone) {
        createFireworkSparks(event);
        await this.checkTask({ projectId, taskId });

        await sleep(500);
        this.$router.push({ name: "ProjectTaskWizard", params: { projectId } });
      } else {
        await this.uncheckTask({ projectId, taskId });
      }

      this.isSending = false;
    },
  },
};
</script>
