<template>
  <div>
    <b-row no-gutters>
      <!-- <div :class="{ 'col-6': currentDocumentId, 'col-12': !currentDocumentId }" class="p-0 ui-chatbot-wrapper"> -->
      <div class="col-12 p-0 ui-chatbot-wrapper">
        <ui-chatbot
          @test="alert('success')"
          @reply="triggerReply"
          @remove="triggerRemove"
          @retry="triggerRetry"
          :theme="CHATBOT_THEME"
          :messages="messages"
          :bot="DEFAULT_ASSISTANT"
          :component-reply="BaseChatReply"
          :dynamic-component="BaseChatSuggestions"
          :references-component="BaseChatReferences"
          :is-text-only="true"
          :is-showing-picture="isShowingPicture"
          :is-shifting-messages="false"
          :is-removable="true"
          :is-hiding-reply="isHidingReply"
          :is-sending="isSending"
          :is-loading="isLoading"
          :error-text="errorText"
          :max-number-of-characters="600000 * 2000"
          welcome-text=""
          :placeholder="`Insert your message here...` | __t('placeholder__insert-your-message-here')"
          class="ui-chatbot w-100"
          style="max-width: 825px"
          :style="styleUiChatbot"
        />
      </div>
    </b-row>

    <transition name="slide">
      <div v-if="currentDocumentId" class="pl-4 pb-4 bg-black rounded-lg rounded-right-0 document-overlay">
        <base-document
          @close="currentDocumentId = null"
          :project-id="projectId"
          :document-id="currentDocumentId"
          :is-showing-close="true"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE, UPDATE, REMOVE, RESPOND } from "@/store/actions";

import UiChatbot from "ui-chatbot";
import { BRow } from "bootstrap-vue";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_THEME, CHATBOT_ROLES, DEFAULT_WELCOME_TEXT, DEFAULT_ASSISTANT } from "@/constants/aiChat";

import BaseDocument from "@/components/Base/Document";
import BaseChatReply from "@/components/Base/Chat/Reply";
import BaseChatReferences from "@/components/Base/Chat/References";
import BaseChatSuggestions from "@/components/Base/Chat/Suggestions";

export default {
  name: "Task",
  components: { BRow, UiChatbot, BaseDocument },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },

    messages: {
      type: Array,
      default: () => [],
    },

    isHidingReply: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleUiChatbot() {
      const { isHidingReply } = this;
      if (isHidingReply) return ``;
      // return "height: calc(100vh - 300px);";
      return "height: 100%";
    },

    isShowingPicture() {
      // const { isMobile } = this;
      // return !isMobile;
      return true;
    },
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    currentDocumentId: null,

    errorText: null,

    isLoading: false,
    isSending: false,

    BaseChatReply,
    BaseChatReferences,
    BaseChatSuggestions,

    CHATBOT_THEME,
    CHATBOT_MESSAGE_TYPE,
    DEFAULT_WELCOME_TEXT,
    DEFAULT_ASSISTANT,
  }),
  mounted() {
    document.addEventListener("click", this.preventLinkGlobal);
    document.addEventListener("keydown", this.handleEscKey);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.preventLinkGlobal);
    document.removeEventListener("keydown", this.handleEscKey);
  },
  watch: {
    taskId() {
      this.currentDocumentId = null;
    },
  },
  methods: {
    ...mapActions("projectsTasksDiscussions", {
      createMessage: CREATE,
      updateMessage: UPDATE,
      removeMessage: REMOVE,
      respondMessage: RESPOND,
    }),
    async triggerReply(lastMessage, options = {}) {
      this.errorText = null;
      this.isSending = true;

      const { isRetrying } = options;
      const { projectId, taskId } = this;

      if (!isRetrying) await this.createMessage({ projectId, taskId, ...lastMessage });

      this.scrollToBottom();

      if (lastMessage.role === CHATBOT_ROLES.USER) {
        const { isSuccess, errorCode /* ...data */ } = await this.respondMessage({
          projectId,
          taskId,
          messages: this.messages,
        });

        if (!isSuccess) {
          this.errorText = errorCode;
          return;
        }

        // let contentData = {};
        // if (data.contentName) {
        // contentData = this.createSuggestionCard(data.contentName, data.contentJSON);
        // }

        // const chatbotMessage = { ...data, ...contentData };
        // if (chatbotMessage && chatbotMessage.content) await this.createMessage({ projectId, taskId, ...chatbotMessage });
      }

      this.scrollToBottom(); // TODO: That should be handled in the ui-chatbot
      this.isSending = false;
    },
    async triggerUpdate({ id, content }) {
      this.isSending = true;

      const { projectId, taskId } = this;
      const type = CHATBOT_MESSAGE_TYPE.TEXT;

      await this.updateMessage({ projectId, taskId, id, type, content });

      this.isSending = false;
    },
    async triggerRemove({ id }) {
      this.isSending = true;

      const { projectId, taskId } = this;
      await this.removeMessage({ projectId, taskId, id });

      this.isSending = false;
    },
    async triggerRetry(lastMessage) {
      this.triggerReply(lastMessage, { isRetrying: true });
    },

    preventLinkGlobal(event) {
      const el = event.target.closest("a");

      if (el && el.href.includes("/documents/")) {
        event.preventDefault();

        const documentId = el.href.split("/").pop();
        this.currentDocumentId = documentId;
      }
    },
    handleEscKey(event) {
      if (event.key === "Escape") {
        this.currentDocumentId = null;
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const messageContainer = document.querySelector(".messages");
        const chatbotContainer = document.querySelector(".ui-chatbot");

        chatbotContainer.scrollTop = messageContainer.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss">
.message__bubble {
  color: white !important;

  div {
    font-size: 1.3rem;
  }
}

.ui-chatbot {
  display: flex;
  flex-direction: column;

  .messages {
    flex-grow: 1;
  }

  /* .reply {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100px;
  } */

  textarea {
    background-color: #272a34 !important;
    border-color: #9ba0a5 !important;
    color: white !important;
    font-size: 1.3rem;
  }

  textarea::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }

  .document-name,
  .file-name {
    font-family: monospace;
    font-size: 1rem;
    background-color: #2b2d31;
    border-radius: 25px;
    padding: 2px 8px;
    // display: inline-flex;
    // align-items: center;
    // gap: 8px;

    h1 {
      font-family: monospace;
      font-size: 1rem;
    }

    em {
      display: none;
    }
  }

  .file-name::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/meet-louis.appspot.com/o/common%2Fattached.svg?alt=media&token=6a738250-5a0e-4cab-9057-d9bbbecaf7d2");
    background-size: cover;
    margin-right: 10px;
  }
  .document-name::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/meet-louis.appspot.com/o/common%2Fdocument.svg?alt=media&token=066d7e93-9321-48d2-9c54-03fbf2774c28");
    background-size: cover;
  }
}

.document-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
</style>
