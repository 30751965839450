<template>
  <b-row no-gutters>
    <b-col>
      <div @click="handleShowSidebar">
        <b-button variant="transparent" class="mt-n2 d-inline-block">
          <img src="@/assets/icons/chevron-left.svg" width="20" height="20" />
        </b-button>

        <tip-tap-headline-small :value.sync="localName" placeholder="No Name" class="d-inline-block mt-2 ml-1" />
      </div>
    </b-col>

    <b-col cols="2" class="mt-1 mr-2 text-right">
      <b-button @click="$emit('check-task')" :variant="taskVariant" class="mt-1 pl-3 pr-3" :disabled="isSending" pill>
        <img src="@/assets/icons/check.svg" width="20" height="20" class="mr-2" />
      </b-button>

      <!-- <base-dropdown class="d-inline-block mt-2 mr-2" @remove="$emit('remove-task')" /> -->
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";

import TipTapHeadlineSmall from "@/components/Forms/TipTap/HeadlineSmall";
// import BaseDropdown from "@/components/Base/Dropdown";

export default {
  name: "HeaderMobile",
  components: {
    BRow,
    BCol,
    BButton,
    TipTapHeadlineSmall,
    // BaseDropdown,
  },
  props: {
    taskName: {
      type: String,
      default: "",
    },
    taskVariant: {
      type: String,
      default: "",
    },
    taskText: {
      type: String,
      default: "",
    },

    isSending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localName: {
      get() {
        return this.taskName;
      },
      set(value) {
        this.$emit("update:task-name", value);
      },
    },
  },
  methods: {
    handleShowSidebar() {
      this.$bus.emit("show-sidebar");
    },

    toSlug,
  },
};
</script>
