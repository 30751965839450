<template>
  <div class="h-100">
    <vue-lottie-player
      v-if="isLoading"
      name="loader"
      loop
      :animation-data="require('@/assets/animations/spinner.json')"
      class="ml-auto mr-auto"
      style="height: 100px"
    />

    <div v-else :key="keyReset">
      <task-header :project-id="projectId" :task-id="taskId" :task="task" />

      <task-chat
        :project-id="projectId"
        :task-id="taskId"
        :action-id="task?.actionId"
        :messages="messages"
        :is-hiding-reply="isHidingReply"
      />

      <task-first-message
        v-if="replyFormat === CHATBOT_MESSAGE_TYPE.FORM"
        :project-id="projectId"
        :task-id="taskId"
        :action-id="task?.actionId"
        :format="replyFormat"
        class="ml-4 mr-4"
      />

      <task-upload
        v-if="replyFormat === CHATBOT_MESSAGE_TYPE.FILE"
        :project-id="projectId"
        :task-id="taskId"
        :action-id="task?.actionId"
        class="ml-4 mr-4"
      />

      <task-select-document
        v-if="replyFormat === CHATBOT_MESSAGE_TYPE.DOCUMENT"
        :project-id="projectId"
        :task-id="taskId"
        :action-id="task?.actionId"
        class="ml-4 mr-4"
      />

      <div v-if="task?.hasWorkingJob">
        <div v-if="task?.jobs" class="ml-auto mr-auto pl-4 pr-4 pb-4 bg-dark-jungle rounded-lg" style="max-width: 655px">
          <vue-lottie-player
            name="scooterAnim"
            loop
            :animation-data="require('@/assets/animations/spinner.json')"
            class="ml-auto mr-auto"
            style="height: 100px"
          />

          <div class="mt-3 pt-3 border-top">
            <h5 class="text-uppercase">{{ "Checklist" | __t("projects__task__headline__checklist") }}</h5>
            <base-checklist :items="task?.jobs?.[task.workingJobId].tasksList" />
          </div>

          <div class="mt-3 pt-3 border-top">
            <img src="@/assets/character/smiling.png" class="float-left" width="100" />

            <p class="p-3 bigger">
              {{
                "I'm currently working on the checklist... I'll send you a message when I'm done."
                  | __t("projects__task__i-m-currently-working-on-the-checklist")
              }}
              <br />
              {{
                "Meanwhile, you can leave this page or start a new task."
                  | __t("projects__task__meanwhile-you-can-leave-this-page")
              }}
            </p>
          </div>
        </div>

        <b-button v-if="NODE_ENV === 'development'" @click="triggerCheckBatch" variant="primary">
          {{ "Check Batch" | __t("button__check-batch") }}
        </b-button>
      </div>
    </div>

    <layout-spacer />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FETCH, GET, CREATE, UPDATE, LISTEN, UNLISTEN, CHECK, RENAME } from "@/store/actions";

import { BButton } from "bootstrap-vue";
import VueLottiePlayer from "vue-lottie-player";

import { CHATBOT_MESSAGE_TYPE } from "@/constants/aiChat";
import { DEFAULT_REPLY } from "@/constants/tasks";

import { toSlug, generateKey } from "@/helpers/utils";

import BaseChecklist from "@/components/Data/Checklist";
import LayoutSpacer from "@/components/Layouts/Spacer";

import TaskHeader from "./Header/";
import TaskChat from "./Chat";
import TaskFirstMessage from "./FirstMessage/";
import TaskUpload from "./Upload";
import TaskSelectDocument from "./SelectDocument";

export default {
  name: "Task",
  components: {
    BButton,
    VueLottiePlayer,
    BaseChecklist,
    LayoutSpacer,
    TaskHeader,
    TaskChat,
    TaskFirstMessage,
    TaskUpload,
    TaskSelectDocument,
  },
  computed: {
    ...mapGetters("projectsTasks", ["taskById", "itemsSortedByTaskId"]),
    ...mapGetters("projectsTasksDiscussions", ["itemsSortedByTaskId"]),
    projectId() {
      return this.$route.params.projectId;
    },
    taskId() {
      return this.$route.params.taskId;
    },
    task() {
      const { taskId } = this;
      return this.taskById({ id: taskId });
    },
    messages() {
      const { taskId } = this;
      return this.itemsSortedByTaskId(taskId);
    },
    numberOfMessages() {
      return this.messages.length;
    },

    replyFormat() {
      const { messages } = this;
      if (!messages.length) return null;

      return messages[messages.length - 1].reply?.format || null;
    },

    localName: {
      get() {
        return this.task?.name;
      },
      set(name) {
        const { projectId, taskId } = this;
        this.updateTask({ projectId, taskId, name });
      },
    },

    isShowingWelcomeText() {
      const { messages } = this;
      return !messages.length;
    },
    isHidingReply() {
      const { replyFormat, task } = this;

      if (replyFormat === CHATBOT_MESSAGE_TYPE.FILE) return true;
      if (replyFormat === CHATBOT_MESSAGE_TYPE.DOCUMENT) return true;
      if (replyFormat === CHATBOT_MESSAGE_TYPE.URL) return true;
      if (replyFormat === CHATBOT_MESSAGE_TYPE.FORM) return true;
      if (task?.hasWorkingJob) return true;
      return false;
    },
  },
  data: () => ({
    errorText: null,

    isLoading: false,
    isSending: false,

    keyReset: generateKey(),

    CHATBOT_MESSAGE_TYPE,
    DEFAULT_REPLY,

    NODE_ENV: process.env.NODE_ENV,
  }),
  mounted() {
    this.init();
  },
  beforeDestroy() {
    const { taskId } = this;
    this.unlistenMessages({ taskId });
  },
  watch: {
    taskId() {
      this.init();
      this.keyReset = generateKey();
    },
    numberOfMessages(newValue) {
      const { localName } = this;
      if (newValue === 3 && localName === "No Name") this.triggerRenameTask();
    },
  },
  methods: {
    ...mapActions("projectsBatchs", { checkBatch: CHECK }),
    ...mapActions("projectsTasks", {
      getTask: GET,
      updateTask: UPDATE,
      renameTask: RENAME,
    }),
    ...mapActions("projectsTasksDiscussions", {
      fetchMessages: FETCH,
      createMessage: CREATE,
      listenMessage: LISTEN,
      unlistenMessages: UNLISTEN,
    }),
    async init() {
      this.isLoading = true;

      const { projectId, taskId } = this;
      await this.getTask({ projectId, taskId });

      await this.fetchMessages({ projectId, taskId });
      await this.listenMessage({ projectId, taskId });

      this.scrollToBottom();

      this.isLoading = false;
    },

    async triggerRenameTask() {
      this.isSending = true;

      const { projectId, taskId, messages } = this;
      await this.renameTask({ projectId, taskId, messages });

      this.isSending = false;
    },

    async triggerCheckBatch() {
      const { projectId, taskId } = this;
      const { batchId } = this.task;

      await this.checkBatch({ projectId, taskId, batchId });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const messageContainer = document.querySelector(".messages");
        const chatbotContainer = document.querySelector(".ui-chatbot");

        if (messageContainer) chatbotContainer.scrollTop = messageContainer.scrollHeight;
      });
    },

    toSlug,
  },
};
</script>
