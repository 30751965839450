<template>
  <div class="select-area rounded-lg p-4">
    <h5 v-if="headline" class="text-uppercase">{{ headline }}</h5>

    <form-builder
      :state-machine="stateMachine"
      :components-list="componentsList"
      :custom-validators="{}"
      @submit="nextStep"
      class="form-wizard"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CREATE } from "@/store/actions";

import { FormBuilder } from "ui-stepper";
import cloneDeep from "lodash/cloneDeep";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_ROLES } from "@/constants/aiChat";
import { DEFAULT_REPLY, ACTION_BY_TEAMMATE } from "@/constants/tasks";

import { getTranslation } from "@/filters/translation";
import { toSlug } from "@/helpers/utils";
import { sanitizeTextWithContext } from "@/helpers/ai";
import { stateMachineTranslater } from "@/helpers/uiStepper";

export default {
  name: "SelectUrl",
  components: { FormBuilder },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },
    actionId: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("projectsTeammates", ["teammatesSortedOptions"]),
  },
  data: () => ({
    formData: {},

    headline: null,
    stateMachine: {},
    componentsList: {
      Message: () => ({
        component: import("@/components/Forms/TipTap/Paragraph.vue"),
        timeout: 3000,
      }),
      SeoKeywords: () => ({
        component: import("@/components/Forms/Base/SeoKeywords.vue"),
        timeout: 3000,
      }),
    },

    DEFAULT_REPLY,
  }),
  created() {
    const { actionId } = this;

    this.headline = getTranslation(
      DEFAULT_REPLY[actionId].headline,
      toSlug(`state-machine__headlines__${toSlug(DEFAULT_REPLY[actionId].headline)}`)
    );
    this.stateMachine = stateMachineTranslater(DEFAULT_REPLY[actionId].stateMachine);

    if (ACTION_BY_TEAMMATE[actionId]) {
      const personaOptions = this.teammatesSortedOptions({ type: ACTION_BY_TEAMMATE[actionId] });
      personaOptions.push({ value: null, text: "None" });

      this.stateMachine.steps["step-post"].options.fields[0].attributes.options = personaOptions;
    }
  },
  methods: {
    ...mapActions("projectsTasksDiscussions", { createMessage: CREATE }),
    async triggerSuccess() {
      const { projectId, taskId, actionId, formData } = this;
      const { storeDispatch, defaultData = {} } = DEFAULT_REPLY[actionId];
      let { firstMessage, firstMessageSuffix } = DEFAULT_REPLY[actionId];

      firstMessage = getTranslation(firstMessage, toSlug(`first-message__${actionId}__${firstMessage}`));
      if (firstMessageSuffix) firstMessage = `${firstMessage} ${firstMessageSuffix}`;

      const format = CHATBOT_MESSAGE_TYPE.FILE;
      const role = CHATBOT_ROLES.USER;
      const content = sanitizeTextWithContext(firstMessage, formData);

      await this.createMessage({ projectId, taskId, role, format, content });
      console.log("defaultData", { ...defaultData });

      const { content: contentReply, contentSuffix: contentSuffixReply } = defaultData.message;
      defaultData.message.content = getTranslation(contentReply, toSlug(`reply-message__${actionId}__${contentReply}`));
      if (contentSuffixReply) {
        defaultData.message.content = `${defaultData.message.content} ${contentSuffixReply}`;
        delete defaultData.message.contentSuffix;
      }

      await this.$store.dispatch(storeDispatch, { projectId, taskId, ...defaultData, ...formData });
    },

    nextStep() {
      const { formData } = this.stateMachine.context;

      this.formData = cloneDeep(formData);
      this.triggerSuccess();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.select-area {
  background-color: #151718;
}
</style>
