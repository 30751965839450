import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  // Placeholder,
} from "tiptap-extensions";

import { Autofill } from "@/helpers/tiptap/Autofill";
// import { DisableEnter } from "@/helpers/tiptap/DisableEnter";
import { CleanPaste } from "@/helpers/tiptap/CleanPaste";

export const DEFAULT_EXTENSIONS = [
  new Blockquote(),
  new CodeBlock(),
  new HardBreak(),
  new Heading({ levels: [1, 2, 3] }),
  new BulletList(),
  new OrderedList(),
  new ListItem(),
  new TodoItem(),
  new TodoList(),
  new Bold(),
  new Code(),
  new Italic(),
  new Link(),
  new Strike(),
  new Underline(),
  new History(),
  new Autofill(),
  // new DisableEnter(),
  new CleanPaste(),
];

export const DEFAULT_BUBBLE_SIZE = { width: 350, height: 45 };
