<template>
  <div>
    <editor-menu v-if="isShowingMenu" :editor="editor" />
    <editor-floating v-if="editor" :editor="editor" />
    <editor-mentions :editor="editor" :query="mentionsQuery" :is-showing-mentions="isShowingMentions" />
    <editor-content :editor="editor" class="tiptap" />
  </div>
</template>

<script>
import { Editor } from "@tiptap/core";
import { EditorContent } from "@tiptap/vue-2";

import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Mention from "@tiptap/extension-mention";
import Image from "@tiptap/extension-image";

import { UniqueIdExtension } from "@/helpers/tiptap/UniqueId";

import { BlockContent } from "./Content/Extension";
import { BlockSubtitles } from "./Subtitles/Extension";

import EditorMenu from "./Menu";
import EditorFloating from "./Floating";
import EditorMentions from "./Mentions";

export default {
  name: "TipTapDocument",
  components: { EditorMenu, EditorFloating, EditorMentions, EditorContent },
  props: {
    id: String,
    placeholder: {
      type: String,
      default: "Tell your story...",
    },
    value: {
      type: [String, Object],
      default: () => {},
    },

    format: {
      type: String,
      default: "json",
    },
  },
  data() {
    return {
      editor: null,

      mentionsQuery: "",

      isShowingMenu: false,
      isShowingMentions: false,
    };
  },
  mounted() {
    this.initializeEditor();
  },
  beforeDestroy() {
    this.editor.off("update", this.handleUpdate);
    this.editor.off("transaction", this.handleTransaction);

    this.editor.destroy();
  },
  methods: {
    initializeEditor() {
      const { value } = this;

      this.editor = new Editor({
        extensions: [
          StarterKit,
          UniqueIdExtension,
          Underline,
          Image,
          BlockContent,
          BlockSubtitles,
          Placeholder.configure({
            emptyEditorClass: "is-editor-empty",
            emptyNodeClass: "is-empty",
            placeholder: this.placeholder,
          }),
          Mention.configure({
            HTMLAttributes: {
              class: "mention",
            },
            suggestion: {
              char: "@", // Trigger character
              items: () => {
                // Example: Fetch your mentionable items based on the query
                return [
                  { id: 1, label: "Vue.js" },
                  { id: 2, label: "React" },
                  // Dynamically load more items based on the query
                ];
              },
              render: () => {
                return {
                  onStart: () => {
                    this.isShowingMentions = true;
                    this.mentionsQuery = "";
                  },
                  onUpdate: (props) => {
                    this.mentionsQuery = props.query;
                  },
                  onKeyDown: () => {},
                  onExit: () => {
                    this.mentionsQuery = "";
                    this.isShowingMentions = false;
                  },
                };
              },
            },
          }),
        ],
        content: "",
      });

      this.editor.commands.setContent(value);

      this.editor.on("update", this.handleUpdate);
      this.editor.on("transaction", this.handleTransaction);
    },
    handleTransaction() {
      const { selection } = this.editor.state;

      if (selection && !selection.empty && !selection.node) this.isShowingMenu = true;
      else this.isShowingMenu = false;
    },
    handleUpdate() {
      const { format } = this;

      let content = format === "json" ? this.editor.getJSON() : this.editor.getHTML();

      this.$emit("update:value", content);
    },
  },
};
</script>

<style lang="scss">
.tiptap {
  .is-empty::before {
    position: absolute;
    content: attr(data-placeholder);
    color: #a9a9a9;
    pointer-events: none;
  }

  .ProseMirror:focus {
    outline: none;
    border: none;
  }

  p {
    margin-top: 10px;
  }

  blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    border-left: 5px solid #ccc;
    font-style: italic;
    quotes: "\201C""\201D""\2018""\2019";
  }

  blockquote:before {
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    color: #ccc;
  }

  blockquote:after {
    content: close-quote;
  }

  blockquote p {
    display: inline;
    font-size: 1em;
  }
}

.tiptap.is-sending {
  .is-empty {
    display: none !important;
  }
}
</style>
