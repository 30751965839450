<template>
  <node-view-wrapper>
    <spinner-small v-if="isLoading" />

    <div v-else>
      <h5 v-if="headline">{{ headline }}</h5>
      <base-tip-tap :value.sync="content" :placeholder="placeholder" />
    </div>
  </node-view-wrapper>
</template>

<script>
import { mapActions } from "vuex";
import { GET, UPDATE } from "@/store/actions";

import { NodeViewWrapper } from "@tiptap/vue-2";
import debounce from "lodash/debounce";

import SpinnerSmall from "@/components/Spinner/Small";
import BaseTipTap from "@/components/Forms/TipTap/Paragraph";

export default {
  components: {
    NodeViewWrapper,
    SpinnerSmall,
    BaseTipTap,
  },
  props: {
    node: Object,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },

  data: () => ({
    headline: "",
    content: "",
    placeholder: "",

    isLoading: true,
    isShowingModal: false,
  }),
  mounted() {
    this.triggerGetDocumentBlock();
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.updateBlock();
      },
    },
  },
  methods: {
    ...mapActions("projectsDocumentsBlocks", { getDocumentBlock: GET, updateDocumentBlock: UPDATE }),
    async triggerGetDocumentBlock() {
      this.isLoading = true;

      const { projectId } = this;
      const { dataDocumentId: documentId, dataBlockId: blockId } = this.node.attrs;

      const result = await this.getDocumentBlock({ projectId, documentId, blockId });

      this.headline = result?.headline || "";
      this.content = result?.content || "";
      this.placeholder = result?.placeholder || "";

      this.isLoading = false;
    },
    async triggerUpdateDocumentBlock() {
      this.isSending = true;

      const { projectId, content } = this;
      const { dataDocumentId: documentId, dataBlockId: blockId } = this.node.attrs;

      const fieldPath = "content";
      const value = content;

      await this.updateDocumentBlock({ projectId, documentId, blockId, fieldPath, value });

      this.isSending = false;
    },
    updateBlock: debounce(function(value) {
      this.triggerUpdateDocumentBlock(value);
    }, 2000),
  },
};
</script>
