<template>
  <b-dropdown no-caret right variant="transparent" menu-class="base-dropdown">
    <template #button-content>
      <b-button variant="transparent">
        <img src="@/assets/icons/ellipsis.svg" class="icon" />
      </b-button>
    </template>

    <b-dropdown-item @click="$emit('remove')">
      {{ "Remove" | __t("button__remove") }}
    </b-dropdown-item>

    <b-dropdown-item v-if="canUndo" @click="$emit('undo')" :disabled="!canUndo">
      {{ "Undo" | __t("button__undo") }}
    </b-dropdown-item>

    <b-dropdown-item v-if="canRedo" @click="$emit('redo')" :disabled="!canRedo">
      {{ "Redo" | __t("button__redo") }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem, BButton } from "bootstrap-vue";

export default {
  name: "BaseDropdown",
  props: {
    canUndo: {
      type: Boolean,
      default: false,
    },
    canRedo: {
      type: Boolean,
      default: false,
    },
  },
  components: { BDropdown, BDropdownItem, BButton },
};
</script>

<style>
.base-dropdown {
  background-color: #151718;
}

.base-dropdown .dropdown-item {
  background-color: #151718;
  border-bottom: 1px #272a34 solid;
  color: white;
  transition: transform 0.7s ease-in-out;
}
.base-dropdown .dropdown-item:hover {
  background-color: #5900d9;
}
</style>
